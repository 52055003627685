<template>
  <div class="relative flex min-h-screen flex-col overflow-hidden bg-ocean">
    <!-- header -->
    <div class="mx-auto mt-[18px] flex items-center gap-2">
      <NuxtImg src="/images/logo-icon.png" class="w-[43px]" alt="DDC Logo" />
      <p
        class="font-Kontesa text-[23px] font-bold -tracking-[0.03em] text-navy"
      >
        Dollar Donation Club
      </p>
    </div>
    <div class="mx-4">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "Dollar Donation Club",
  meta: [
    {
      name: "theme-color",
      content: "#61E5FF",
    },
  ],
});
</script>
